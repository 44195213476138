import React, {useState, useEffect} from "react";

import Pc_video from "./pc_video";
import Mobile_video from "./mobile_video";


const Video = ({device}) => {

    switch (device) {
        case'pc':
            return <Pc_video />
        case 'mobile':
            return <Mobile_video />
        default:
            break;
    }
}

export default Video
