import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {API_SERVER} from "../../config";

import Pc_home from "./pc_home";
import Mobile_home from "./mobile_home";

const Home = ({device}) => {

    const user = useSelector(store => store['User_reducers']);
    const [surveyList, setSurveyList] = useState(null);
    const [report, setReport] = useState(null);
    const [survey, setSurvey] = useState(null);

    useEffect(() => {
        var {id, auth_code} = user;
        if (auth_code === 'admin') {
            fetch(API_SERVER + '/video/getTotalReport')
                .then(res => res.json())
                .then(json => {
                    if (json['suc']) {
                        setReport(json.data)
                    }
                })

            fetch(API_SERVER + '/data/totalAnswerList')
                .then(res => res.json())
                .then(json => {
                    if(json['suc']){
                        setSurvey(json.result)
                    }
                })

        } else {
            fetch(API_SERVER + '/video/getReport', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({user_id: id})
            })
                .then(res => res.json())
                .then(json => {
                    if (json['suc']) {
                        setReport(json.report)
                    }
                })

            fetch(API_SERVER + '/data/getSurveyListByUser', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({user_id: id})
            })
                .then(res => res.json())
                .then(json => {
                    if(json['suc']){
                        setSurvey(json.result);
                    }
                })

        }
    },[user])

    useEffect(() => {
        fetch(API_SERVER + '/data/getSurveyList')
            .then(res => res.json())
            .then(json => {
                setSurveyList(json.result)
            })
    },[])


    if(report && survey && surveyList)
        switch (device){
            case 'pc':
                return <Pc_home report={report} survey={survey} surveyList={surveyList}/>
            case 'mobile':
                return <Mobile_home />
            default:
                break;
        }
}

export default Home
