import React, {useState, useEffect, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import ProgressBar from "react-bootstrap/ProgressBar";
import {CardChecklist, CheckCircleFill, Circle, GearFill} from "react-bootstrap-icons";

import {PcContainer, PcContentWrapper, MainColor, SubColor01, SubColor02, SubColor03} from "../../../module/styled";
import MenuBar from "../../../module/common/menuBar";


const Pc_survey_detail = ({surveyList}) => {

    const navigate = useNavigate();
    const location = useLocation();

    var scrollRef = useRef([]);
    const [answer, setAnswer] = useState(null);


    useEffect(() => {
        if(location && location.state){
            setAnswer(location.state['answer'])
        } else {
            navigate(-1);
            alert('잘못된 접근입니다.')
        }
    },[location]);



    return (
        <PcContainer>
            <MenuBar device={'pc'}/>
            <PcContentWrapper>
                {
                    answer &&
                    <div style={{width: '90%', marginBottom: '5vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                        <div style={{
                            // position: 'sticky', top: 0,
                            width: '100%', padding: '3vh 0', display: 'flex',
                            alignItems: 'flex-start', justifyContent: 'center',
                            backgroundColor: 'white', border: `1px solid ${SubColor01}80`
                        }}>
                            <div style={{width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                <div style={{
                                    width: '100%',  display: 'flex',
                                    flexDirection: 'column', alignItems: 'flex-start',
                                    border: `1px solid ${SubColor03}`
                                }}>

                                    <div style={{
                                        width: '100%', height: '4vh', display: 'flex',
                                        alignItems: 'center', justifyContent: 'flex-start',
                                        backgroundColor: SubColor03
                                    }}>
                                        <div style={{marginLeft: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <CardChecklist size={'1.1vw'} color={SubColor02}/>
                                            <span style={{marginLeft: 8, color: SubColor02, fontSize: '0.9vw', fontWeight: 'bold'}}>{location.state && location.state['company_name']}님의 자가진단 진행도</span>
                                        </div>
                                    </div>

                                    <div style={{
                                        width: '100%', height: '6vh', display: 'flex',
                                        alignItems: 'center', justifyContent: 'center'
                                    }}>
                                        <div style={{width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <ProgressBar now={Math.round(answer.filter(x => x['answer_radio']).length/surveyList.length * 100)}
                                                         label={`${answer.filter(x => x['answer_radio']).length}/${surveyList.length}`}
                                                         style={{
                                                             width: '95%', height: '3vh', fontWeight: 'bold',
                                                             border: `1px solid ${MainColor}80`, background: 'none'
                                                         }}/>

                                            <span style={{fontSize: '0.9vw', fontWeight: 'bold'}}>{Math.round(answer.filter(x => x['answer_radio']).length/surveyList.length * 100) + '%'}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    width: '100%',  display: 'flex', marginTop: '2vh',
                                    flexDirection: 'column', alignItems: 'flex-start',
                                    border: `1px solid ${SubColor03}`
                                }}>

                                    <div style={{
                                        width: '100%', height: '4vh', display: 'flex',
                                        alignItems: 'center', justifyContent: 'space-between',
                                        backgroundColor: SubColor03, padding: '0 15px'
                                    }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <GearFill size={'1.1vw'} color={SubColor02}/>
                                            <span style={{marginLeft: 8, color: SubColor02, fontSize: '0.9vw', fontWeight: 'bold'}}>답변 요약</span>
                                        </div>

                                        <span style={{fontSize: '0.7vw', fontWeight: 'bold',color: 'red'}}>수행사 요청사항이 기재되어 있을 시 지표 번호는 빨간색으로 표시됩니다.</span>
                                    </div>

                                    <div style={{
                                        width: '100%', padding: '1.5vh 0', display: 'flex',
                                        alignItems: 'center', justifyContent: 'center'
                                    }}>
                                        <div style={{
                                            width: '95%', display: 'flex', flexWrap: "wrap",
                                            alignItems: 'center', justifyContent:'flex-start',
                                            borderLeft: '1px solid #eeeeee', borderRight: '1px solid #eeeeee'
                                        }}>
                                            {
                                                surveyList.map((item, idx) => {
                                                    var _fIdx = answer.findIndex(x => x['survey_code'] === item['survey_code']);
                                                    return (
                                                        <div key={idx}
                                                             style={{
                                                                 width: 100/15 + '%', display: 'flex', flexDirection: 'column',
                                                                 alignItems: 'center', justifyContent: 'flex-start',
                                                                 borderLeft: idx % 15 && '1px solid #eeeeee',
                                                                 borderBottom: '1px solid #eeeeee', cursor: 'pointer',
                                                             }}
                                                             onClick={() => {
                                                                 scrollRef.current[item.survey_code].scrollIntoView();
                                                             }}>
                                                            <div style={{
                                                                width: '100%', height: '3vh', display: 'flex',
                                                                alignItems: 'center', justifyContent: 'center',
                                                                backgroundColor: SubColor03
                                                            }}>
                                                                <span style={{fontSize: '0.7vw', fontWeight: 'bold',
                                                                    color: _fIdx >= 0 && answer[_fIdx]['answer_input'] !== null ? 'red' : SubColor02}}>
                                                                    {item['survey_code']}</span>
                                                            </div>

                                                            <div style={{
                                                                width: '100%', height: '3.5vh', display: 'flex',
                                                                alignItems: 'center', justifyContent: 'center',
                                                                backgroundColor: 'white'
                                                            }}>
                                                                {
                                                                    _fIdx >= 0 ?
                                                                        <span style={{fontSize: '0.7vw', fontWeight: 500}}>
                                                                            {
                                                                                answer[_fIdx]['answer_radio'] ?
                                                                                [
                                                                                    {key: 'Y', show_key: '예'},
                                                                                    {key: 'N', show_key: '아니오'},
                                                                                    {key: 'none', show_key: '해당없음'},
                                                                                ].find(x => x.key === answer[_fIdx]['answer_radio'])['show_key']
                                                                                    :
                                                                                    '미등록'
                                                                            }
                                                                        </span>
                                                                        :
                                                                        <span style={{fontSize: '0.7vw', fontWeight: 500}}>미등록</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style={{width: '100%', marginTop: '3vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            {
                                surveyList.map((survey, sIdx) => {
                                    var _fIdx = answer.findIndex(x => x['survey_code'] === survey['survey_code']);

                                    return (
                                        <div key={sIdx}
                                             ref={(el) => scrollRef.current[survey['survey_code']] = el}
                                             style={{
                                                 width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                                 borderTop: '2px solid gray', marginTop: sIdx && '7.5vh',
                                             }}>
                                            {
                                                [
                                                    [
                                                        {key: 'division',show_key: '분류',},
                                                        {key: 'survey_code',show_key: '지표번호',},
                                                    ],
                                                    [
                                                        {key: 'target', show_key: '대상',},
                                                        {key: 'short_cut', show_key: '지표요약',},
                                                    ],
                                                    [
                                                        {key: 'ques', show_key: '지표내용'}
                                                    ],
                                                    [
                                                        {key: 'ques_desc', show_key: '설명',}
                                                    ],
                                                    [
                                                        {key: 'check', show_key: '점검',},
                                                        {key: 'check_add', show_key: '보조',},
                                                    ],
                                                    [
                                                        {key: 'answer_radio', show_key: '체크리스트',},
                                                    ],
                                                    [
                                                        {key: 'answer_input', show_key: '수행사 요청사항',},
                                                    ]
                                                ].map((item, idx) => {
                                                    return (
                                                        <div key={idx}
                                                             style={{
                                                                 width: '100%', height: 'auto',
                                                                 display: 'flex', alignItems: 'center',
                                                                 borderTop: idx && '1px solid #aaaaaa'
                                                             }}>
                                                            {
                                                                item.map((child, cIdx) => {
                                                                    return (
                                                                        <div key={cIdx}
                                                                             style={{
                                                                                 width: 100 / item.length + '%', height: '100%',
                                                                                 display: 'flex', alignItems: 'center',
                                                                                 borderLeft: cIdx && '1px solid #aaaaaa',
                                                                                 backgroundColor: 'white'
                                                                             }}>
                                                                            <div style={{
                                                                                width: item.length > 1 ? '30%' : '15%', height: '100%',
                                                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                                backgroundColor: `${SubColor03}80`
                                                                            }}>
                                                                                <span style={{fontSize: '0.7vw', fontWeight: 500}}>{child.show_key}</span>
                                                                            </div>

                                                                            <div style={{
                                                                                width: item.length > 1 ? '70%' : '85%', height: '100%',
                                                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                                borderLeft: '1px solid lightgray'
                                                                            }}>
                                                                                <div style={{width: item.length > 1 ? '97%' : '99%', margin: '8px 0',display: 'flex', justifyContent: 'flex-start'}}>
                                                                                    {
                                                                                        (() => {
                                                                                            switch (child.key){
                                                                                                case 'answer_radio':
                                                                                                    return (
                                                                                                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                                                            {
                                                                                                                [
                                                                                                                    {key: 'Y', show_key: '예'},
                                                                                                                    {key: 'N', show_key: '아니오'},
                                                                                                                    {key: 'none', show_key: '해당없음'},
                                                                                                                ].map((el, eIdx) => {
                                                                                                                    return (
                                                                                                                        <div key={eIdx}
                                                                                                                             style={{
                                                                                                                                 width: '33%', display: 'flex', alignItems: 'center',
                                                                                                                                 justifyContent: 'flex-start',
                                                                                                                             }}>
                                                                                                                            {
                                                                                                                                _fIdx >= 0 && answer[_fIdx][child.key] === el.key ?
                                                                                                                                    <CheckCircleFill color={MainColor} size={'0.8vw'}/>
                                                                                                                                    :
                                                                                                                                    <Circle color={'gray'} size={'0.8vw'}/>
                                                                                                                            }
                                                                                                                            <span style={{marginLeft: 5, fontSize: '0.8vw', fontWeight: 600}}>{el.show_key}</span>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                case 'answer_input':
                                                                                                    return (
                                                                                                        <textarea  value={(_fIdx >= 0 && answer[_fIdx][child.key]) || ''}
                                                                                                                   readOnly={true}
                                                                                                                   placeholder={'현장에서 해당여부를 전문가와 함께 체크하길 희망'}
                                                                                                                   style={{
                                                                                                                       width: '100%', minHeight: '3vh', resize: 'vertical',
                                                                                                                       padding: '5px', border: '1px solid lightgray',
                                                                                                                       fontSize: '0.9vw'
                                                                                                                   }}/>
                                                                                                    )
                                                                                                default:
                                                                                                    return (
                                                                                                        <span style={{fontSize: '0.8vw', fontWeight: 500, whiteSpace: 'pre-wrap'}}>{survey[child.key]}</span>
                                                                                                    )
                                                                                            }
                                                                                        })()
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </PcContentWrapper>
        </PcContainer>
    )
}

export default Pc_survey_detail
