import React, {useState, useEffect} from "react";

import Pc_auth from "./pc_auth";
import Mobile_auth from "./mobile_auth";

const Auth = ({device}) => {

    switch (device){
        case 'pc':
            return <Pc_auth />
        case 'mobile':
            return <Mobile_auth />
        default:
            break;
    }
}

export default Auth
