import React, {useState, useEffect} from "react";

const Mobile_auth = () => {


    return (
        <div>

        </div>
    )
}

export default Mobile_auth
