import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useBeforeunload} from "react-beforeunload";

import {CheckCircleFill, Circle} from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";

import {API_SERVER} from "../../../config";
import {PcContainer, PcContentWrapper, PcWidth, MainColor, SubColor02, SubColor03} from "../../../module/styled";

import MenuBar from "../../../module/common/menuBar";

const Pc_survey = ({surveyList}) => {

    const navigate = useNavigate();
    const user = useSelector(store => store['User_reducers']);
    const [answer, setAnswer] = useState(null);

    const adminTableHead = [
        {key: 'no', show_key: '연번', width: '5%',},
        {key: 'company_name', show_key: '회사명', width: '25%',},
        {key: 'progress', show_key: '진행도', width: '60%',},
        {key: 'note', show_key: '비고', width: '10%',},
    ]

    useBeforeunload((e) => {
        e.preventDefault()
    },[])


    useEffect(() => {
        fetch(API_SERVER + '/data/getSurveyListByUser', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: user['id'],
            })
        })
            .then(res => res.json())
            .then(json => {
                if(json['suc']){
                    if(json.result.length > 0){
                        setAnswer(json.result);
                    } else {
                        var _answer = surveyList.reduce((pre, cur) => {
                            pre.push({
                                user_id: user['id'],
                                survey_code : cur['survey_code'],
                                answer_radio: null,
                                answer_input: null,
                            })
                            return pre
                        },[])

                        setAnswer(_answer);
                    }
                }
            })
    },[surveyList])



    const insertAnswer = (ans) => {
        fetch(API_SERVER + '/data/insertAnswer', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: user['user_id'],
                answer: ans
            })
        })
            .then(res => res.json())
            .then(json => {
                if(json['suc']){
                    setAnswer(json.result)
                    alert('성공적으로 결과를 저장하였습니다.')
                } else {
                    console.log("=== JSON ===", json);
                    alert('결과를 저장하는데에 실패하였습니다, 잠시 후 다시 시도해주세요')
                }
            })
    }


    return (
        <PcContainer>
            <MenuBar device={'pc'}/>
            <PcContentWrapper>
                {
                    (() => {
                        switch (user['auth_code']){
                            case 'admin':
                                return (
                                    <div style={{width: '90%', margin: '0 0 5vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <span style={{fontSize: '0.9vw', fontWeight: 500, color: 'gray'}}>자가진단 진행 현황</span>
                                        </div>

                                        <div style={{
                                            position: 'sticky', top: 0, marginTop: '1vh',
                                            width: '100%', height: '3.5vh', display: 'flex',
                                            alignItems: 'center', justifyContent: 'flex-start',
                                            backgroundColor: SubColor03
                                        }}>
                                            {
                                                adminTableHead.map((head, hIdx) => {
                                                    return (
                                                        <div key={hIdx}
                                                             style={{
                                                                 width: head.width, height: '100%', display: 'flex',
                                                                 alignItems: 'center', justifyContent: 'center'
                                                             }}>
                                                            <span style={{fontSize: '0.7vw', fontWeight: 'bold'}}>{head.show_key}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>


                                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                            {
                                                surveyList.map((item, idx) => {
                                                    return (
                                                        <div key={idx}
                                                             style={{
                                                                 width: '100%', height: '5vh', display: 'flex',
                                                                 alignItems: 'center', justifyContent: 'flex-start',
                                                                 borderTop: idx && '1px solid #eeeeee',
                                                                 backgroundColor: idx % 2 ? '#f8f8f8' : 'white',
                                                                 cursor: 'pointer',
                                                             }}
                                                             onClick={() => {
                                                                 navigate('/surveyDetail', {
                                                                     state: {
                                                                         id: item['user_id'],
                                                                         company_name: item['company_name'],
                                                                         answer: item['survey_list']
                                                                     }
                                                                 })
                                                             }}>
                                                            {
                                                                adminTableHead.map((head, hIdx) => {
                                                                    return (
                                                                        <div key={hIdx}
                                                                             style={{
                                                                                 width: head.width, height: '100%', display: 'flex',
                                                                                 alignItems: 'center', justifyContent: 'center',
                                                                             }}>
                                                                            {
                                                                                (() => {
                                                                                    switch (head.key) {
                                                                                        case 'no':
                                                                                            return (
                                                                                                <span style={{fontSize: '0.8vw', color: 'gray', fontWeight: 'bold'}}>{idx + 1}</span>
                                                                                            )
                                                                                        case 'progress':
                                                                                            var _percent = item['survey_list'].length === 0 ? 0 : Math.round(item['survey_list'].filter(x => x['answer_radio']).length / item['survey_list'].length * 100);

                                                                                            return (
                                                                                                <div style={{width: '80%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                                                    <ProgressBar now={_percent} style={{width: '90%', height: '50%'}}
                                                                                                                 label={`${item['survey_list'].filter(x => x['answer_radio']).length}/${item['survey_list'].length}`}/>
                                                                                                    <span style={{fontSize: '0.7vw', fontWeight: 'bold'}}>{_percent + '%'}</span>
                                                                                                </div>
                                                                                            )
                                                                                        case 'note':
                                                                                            return (
                                                                                                <div style={{width: '95%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', lineHeight: 1.1}}>
                                                                                                    <span style={{fontSize: '0.7vw', letterSpacing: 0}}>{`ID : ${item['user_id']}`}</span>
                                                                                                    <span style={{fontSize: '0.7vw', letterSpacing: 0}}>{`PW : ${item['password']}`}</span>
                                                                                                </div>
                                                                                            )
                                                                                        default:
                                                                                            return (
                                                                                                <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{item[head.key]}</span>
                                                                                            )
                                                                                        }
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            case 'user':
                                return (
                                    <div style={{width: '90%', margin: '3vh 0 5vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                        {
                                            answer &&
                                            surveyList.map((survey, sIdx) => {
                                                var _fIdx = answer.findIndex(x => x['survey_code'] === survey['survey_code']);

                                                return (
                                                    <div key={sIdx}
                                                         style={{
                                                             width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                                             borderTop: '2px solid gray', marginTop: sIdx && '7.5vh',
                                                         }}>
                                                        {
                                                            [
                                                                [
                                                                    {key: 'division',show_key: '분류',},
                                                                    {key: 'survey_code',show_key: '지표번호',},
                                                                ],
                                                                [
                                                                    {key: 'target', show_key: '대상',},
                                                                    {key: 'short_cut', show_key: '지표요약',},
                                                                ],
                                                                [
                                                                    {key: 'ques', show_key: '지표내용'}
                                                                ],
                                                                [
                                                                    {key: 'ques_desc', show_key: '설명',}
                                                                ],
                                                                [
                                                                    {key: 'check', show_key: '점검',},
                                                                    {key: 'check_add', show_key: '보조',},
                                                                ],
                                                                [
                                                                    {key: 'answer_radio', show_key: '체크리스트',},
                                                                ],
                                                                [
                                                                    {key: 'answer_input', show_key: '수행사 요청사항',},
                                                                ]
                                                            ].map((item, idx) => {
                                                                return (
                                                                    <div key={idx}
                                                                         style={{
                                                                             width: '100%', height: 'auto',
                                                                             display: 'flex', alignItems: 'center',
                                                                             borderTop: idx && '1px solid #aaaaaa'
                                                                         }}>
                                                                        {
                                                                            item.map((child, cIdx) => {
                                                                                return (
                                                                                    <div key={cIdx}
                                                                                         style={{
                                                                                             width: 100 / item.length + '%', height: '100%',
                                                                                             display: 'flex', alignItems: 'center',
                                                                                             borderLeft: cIdx && '1px solid #aaaaaa',
                                                                                             backgroundColor: 'white'
                                                                                         }}>
                                                                                        <div style={{
                                                                                            width: item.length > 1 ? '30%' : '15%', height: '100%',
                                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                                            backgroundColor: `${SubColor03}80`
                                                                                        }}>
                                                                                            <span style={{fontSize: '0.7vw', fontWeight: 500}}>{child.show_key}</span>
                                                                                        </div>

                                                                                        <div style={{
                                                                                            width: item.length > 1 ? '70%' : '85%', height: '100%',
                                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                                            borderLeft: '1px solid lightgray'
                                                                                        }}>
                                                                                            <div style={{width: item.length > 1 ? '97%' : '99%', margin: '8px 0',display: 'flex', justifyContent: 'flex-start'}}>
                                                                                                {
                                                                                                    (() => {
                                                                                                        switch (child.key){
                                                                                                            case 'answer_radio':
                                                                                                                return (
                                                                                                                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                                                                        {
                                                                                                                            [
                                                                                                                                {key: 'Y', show_key: '예'},
                                                                                                                                {key: 'N', show_key: '아니오'},
                                                                                                                                {key: 'none', show_key: '해당없음'},
                                                                                                                            ].map((el, eIdx) => {
                                                                                                                                return (
                                                                                                                                    <div key={eIdx}
                                                                                                                                         style={{
                                                                                                                                             width: '33%', display: 'flex', alignItems: 'center',
                                                                                                                                             justifyContent: 'flex-start', cursor: 'pointer',
                                                                                                                                         }}
                                                                                                                                         onClick={() => {
                                                                                                                                             var _answer = JSON.parse(JSON.stringify(answer));

                                                                                                                                             if (_answer[_fIdx][child.key] === el.key) {
                                                                                                                                                 _answer[_fIdx][child.key] = null
                                                                                                                                             } else {
                                                                                                                                                 _answer[_fIdx][child.key] = el.key
                                                                                                                                             }

                                                                                                                                             setAnswer(_answer)
                                                                                                                                         }}>
                                                                                                                                        {
                                                                                                                                            answer[_fIdx][child.key] === el.key ?
                                                                                                                                                <CheckCircleFill color={MainColor} size={'0.8vw'}/>
                                                                                                                                                :
                                                                                                                                                <Circle color={'gray'} size={'0.8vw'}/>
                                                                                                                                        }
                                                                                                                                        <span style={{marginLeft: 5, fontSize: '0.8vw', fontWeight: 600}}>{el.show_key}</span>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )
                                                                                                            case 'answer_input':
                                                                                                                return (
                                                                                                                    <textarea  value={answer[_fIdx][child.key] || ''}
                                                                                                                               placeholder={'현장에서 해당여부를 전문가와 함께 체크하길 희망'}
                                                                                                                               style={{
                                                                                                                                   width: '100%', minHeight: '3vh', resize: 'vertical',
                                                                                                                                   padding: '5px', border: '1px solid lightgray',
                                                                                                                                   fontSize: '0.9vw'
                                                                                                                               }}
                                                                                                                               onChange={(e) => {
                                                                                                                                   var _answer = JSON.parse(JSON.stringify(answer));

                                                                                                                                   _answer[_fIdx][child.key] = e.target.value;
                                                                                                                                   setAnswer(_answer)
                                                                                                                               }}/>
                                                                                                                )
                                                                                                            default:
                                                                                                                return (
                                                                                                                    <span style={{fontSize: '0.8vw', fontWeight: 500, whiteSpace: 'pre-wrap'}}>{survey[child.key]}</span>
                                                                                                                )
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            default:
                                break;
                        }
                    })()
                }



                {
                    user['auth_code'] === 'user' &&
                    <div style={{
                        position: 'fixed', top: 0, right: 0, zIndex: 99,
                        width: PcWidth, height: '6vh', display: 'flex',
                        alignItems: 'center', justifyContent: 'center',
                        backgroundColor: '#f0f4f8', borderBottom: `1px solid lightgray`
                    }}>
                        {
                            answer &&
                            <div style={{width: '90%', display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
                                <ProgressBar now={100 / answer.length * answer.filter(x => x['answer_radio'] !== null).length}
                                             label={`${answer.filter(x => x['answer_radio'] !== null).length}/${answer.length}`}
                                             style={{
                                                 width: '85%', height: '3vh', fontWeight: 'bold',
                                                 border: `1px solid ${MainColor}60`, background: 'white'
                                             }}/>

                                <div style={{
                                    width: '10%', height: '3vh', display: 'flex',
                                    alignItems: 'center', justifyContent: 'center',
                                    borderRadius: 8, border: `2px solid ${MainColor}`,
                                    backgroundColor: "whitesmoke", cursor: 'pointer',
                                }}
                                     onClick={() => {
                                         var filteredAnswer = answer.filter(x => x['answer_radio'] !== null);

                                         if(filteredAnswer.length > 0){
                                             if(filteredAnswer.length !== answer.length){
                                                 if(window.confirm('아직 자가진단이 완료되지 않았습니다, 그래도 저장하시겠습니까?')){
                                                     insertAnswer(answer)
                                                 }
                                             } else {
                                                 insertAnswer(answer)
                                             }
                                         } else {
                                             alert('설문을 한 개 이상은 작성 부탁드립니다.')
                                         }
                                     }}>
                                    <span style={{fontSize: '0.8vw', fontWeight: 'bold', color: 'navy'}}>저장하기</span>
                                </div>
                            </div>
                        }
                    </div>
                }
            </PcContentWrapper>
        </PcContainer>
    )
}

export default Pc_survey
