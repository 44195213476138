import React, {useRef, useState, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {PcMenuWidth, MainColor, SubColor01, SubColor02} from "../styled";

const MenuBar = ({device, style}) => {



    const menu = {
        "pc": [{
                key: 'company_logo',
                show_key: null,
                path: null,
                icon_src : null,
                img_src : require('../../img/company_logo_white.png'),
            },
            {
                key: 'user_info',
                show_key: null,
                path: null,
                icon_src : null,
                img_src : null,
            },
            {
                key: 'status',
                show_key: '수강현황',
                path: '/',
                icon_src : require('../../img/icon/status_icon.png'),
                img_src : null,
            },
            // {
            //     key: 'video',
            //     show_key: '영상시청',
            //     path: '/video',
            //     icon_src : require('../../img/icon/video_icon.png'),
            //     img_src : null,
            // },
            {
                key: 'reference',
                show_key: '자료실',
                path: '/reference',
                icon_src : require('../../img/icon/reference_icon.png'),
                img_src : null,
            },
            {
                key: 'survey',
                show_key: '자가진단',
                path: '/survey',
                icon_src : require('../../img/icon/survey_icon.png'),
                img_src : null,
            },
        ],
        "mobile" : []
    }

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(store => store['User_reducers']);
    var pcMenuRef = useRef([])


    switch (device){
        case 'pc':
            return (
                <div style={{
                    position: 'fixed', top: 0, left: 0, zIndex: 999,
                    width: PcMenuWidth, height: '100vh', display: 'flex',
                    alignItems: 'flex-start', justifyContent: 'center',
                    backgroundColor: MainColor, ...style
                }}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {
                            menu[device].map((item, idx) => {
                                switch (item.key){
                                    case 'company_logo':
                                        return (
                                            <div key={idx}
                                                 style={{
                                                     width: '100%', height: '14vh', display: 'flex',
                                                     alignItems: 'center', justifyContent: 'center',
                                                 }}>
                                                <img alt={'company_logo_white'}
                                                     src={item.img_src} style={{width: '50%'}}/>
                                            </div>
                                        )
                                    case 'user_info':
                                        return (
                                            <div key={idx}
                                                 style={{
                                                     width: '100%', height: '10vh', display: 'flex',
                                                     alignItems: 'center', justifyContent: 'center',
                                                     backgroundColor: SubColor01
                                                 }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start',}}>
                                                    <span style={{fontSize: '0.8vw', fontWeight: 'bold', color: 'gray'}}>환영합니다.</span>
                                                    <div style={{display: 'flex', alignItems: 'flex-end', verticalAlign: 'text-bottom'}}>
                                                        <span style={{fontSize: '1.2vw', fontWeight: 'bold'}}>{user['company_name'] && user['company_name']}</span>
                                                        <span style={{marginLeft: 3, fontSize: '0.8vw', fontWeight: 'bold', color: 'gray'}}>님</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    default:
                                        return (
                                            <div key={idx}
                                                 ref={(el) => pcMenuRef.current[item.key] = el}
                                                 onMouseEnter={() => {
                                                     pcMenuRef.current[item.key].style.backgroundColor = '#ffffff80';
                                                 }}
                                                 onMouseLeave={() => {
                                                     pcMenuRef.current[item.key].style.backgroundColor = location.pathname === item.path ? SubColor02 : MainColor
                                                 }}
                                                 onClick={() => navigate(item.path)}
                                                 style={{
                                                     width: '100%', height: '6.5vh', display: 'flex',
                                                     alignItems: 'center', justifyContent: 'center',
                                                     backgroundColor: location.pathname === item.path ? SubColor02 : MainColor,
                                                     cursor: 'pointer',
                                                 }}>
                                                <div style={{display: 'flex', width: '45%', alignItems: 'center', justifyContent: 'flex-start', }}>
                                                    <img src={item.icon_src} alt={`${item.key}_icon`} style={{width: '1.8vw'}}/>
                                                    <span style={{marginLeft: 10, fontSize: '1vw', fontWeight: 'bold', color: 'white'}}>{item.show_key}</span>
                                                </div>
                                            </div>
                                        )
                                }
                            })
                        }
                    </div>

                    <div style={{
                        position: 'absolute', bottom: 0, left: 0,
                        width: '100%', height: '7vh', display: 'flex',
                        alignItems: 'center', justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                         onClick={() => {
                             if(window.confirm('로그아웃 하시겠습니까?')){
                                 dispatch({type: 'LOG_OUT'})
                             }
                         }}>
                        <span style={{fontSize: '1vw', fontWeight: 'bold', color: SubColor02}}>로그아웃</span>
                    </div>
                </div>
            )
        case 'mobile':
            return (
                <div style={{...style}}>

                </div>
            )
        default:
            break;
    }
}



export default MenuBar
