import './App.css';
import {useState, useEffect} from "react";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom"

import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";

import Auth from "./components/auth";
import Home from "./components/home";
import Video from "./components/video";
import Reference from "./components/reference";
import Survey from "./components/survey";
import Survey_detail from "./components/survey_detail";

function App() {

    const location = useLocation();
    const navigate = useNavigate();

    const user = useSelector(store => store['User_reducers']);
    const isMobile = useMediaQuery({maxWidth: 767});

    const [device, setDevice] = useState(null);

    useEffect(() => {
        setDevice(isMobile ? 'mobile' : 'pc')
    },[isMobile])


    useEffect(() => {
        if (Object.keys(user).find(key => user[key].length === 0)) {
            if(location.pathname !== '/auth'){
                navigate('/auth');
            }
        }
    }, [user])


    return (
        <Routes>
            <Route path={'/auth'} element={<Auth device={device}/>}/>
            <Route path={'/'} element={<Home device={device}/>}/>
            <Route path={'/video'} element={<Video device={device}/>}/>
            <Route path={'/reference'} element={<Reference device={device}/>}/>
            <Route path={'/survey'} element={<Survey device={device}/>}/>
            <Route path={'/surveyDetail'} element={<Survey_detail device={device}/>}/>
        </Routes>
    );
}

export default App;
