import React, {useState, useEffect} from "react";
import {useBeforeunload} from "react-beforeunload";
import {useSelector} from "react-redux";

import {API_SERVER} from "../../../config";
import {PcContainer, PcContentWrapper, MainColor, SubColor02, SubColor03} from "../../../module/styled";

import Player from "../../../module/player";
import MenuBar from "../../../module/common/menuBar";

const Pc_video = () => {

    const user = useSelector(store => store['User_reducers']);

    const [list, setList] = useState(null)
    const [video, setVideo] = useState(null)

    useEffect(() => {
        const getVideo = async () => {
            await fetch(API_SERVER + '/video/getVideo', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({user_id: user['id']})
            })
                .then(res => res.json())
                .then(json => {
                    if(json.suc){
                        setList(json.list)
                        setVideo(json.list[0])
                    }
                })
        }

        getVideo()
    },[])


    useBeforeunload((e) => {
        e.preventDefault()
    },[])


    const insertProgress = async (props) => {
        var _report = JSON.parse(JSON.stringify(props))
        // console.log(_report)
        try {
            if (list.find(x => x['video_id'] === _report['video_id'])['play_time'] !== _report['play_time']) {
                await fetch(API_SERVER + '/video/insertReport', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(props)
                })
                    .then(res => res.json())
                    .then(async json => {
                        if (json['suc']) {
                            await fetch(API_SERVER + '/video/getVideo', {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({user_id: props.user_id})
                            })
                                .then(res => res.json())
                                .then(json => {
                                    if (json['suc']) {
                                        setList(json.list);
                                        setVideo(json.list.find(x => x['video_id'] === video['video_id']));
                                    }
                                })

                        }
                    })
            } else {
                // console.log('NO_CHANGE')
            }
        } catch (e){
            console.log(e)
        }
    }

    const getMin = (sec) => {
        const mm = parseInt((sec%3600)/60) < 10 ? '0' + parseInt((sec%3600)/60) : parseInt((sec%3600)/60)
        const ss = Math.round(sec%60) < 10 ? '0' + Math.round(sec%60) : Math.round(sec%60)

        return (`${String(mm)}:${String(ss)}`)
    }



    return (
        <PcContainer>
            <MenuBar device={'pc'}/>
            <PcContentWrapper>
                <div style={{width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <span style={{fontSize: '0.9vw', fontWeight: 500, color: 'gray'}}>영상시청</span>
                    {
                        list &&
                            <div style={{
                                width: '100%', display: 'flex',  marginTop: '3vh',
                                alignItems: 'flex-start', justifyContent: 'space-between',
                            }}>
                                {
                                    video &&
                                    <div style={{width :'72%', display: 'flex', flexDirection: 'column'}}>

                                        <div style={{width: '100%', display: 'flex', alignItems:'flex-end', justifyContent: 'space-between', marginBottom: '1.85vh'}}>
                                            <span style={{fontFamily: 'Spoqa Han Sans Neo', fontSize: '1.5vw', fontWeight: 'bold'}}>{video['title']}</span>

                                            <div style={{display : 'flex', alignItems: 'center'}}>

                                                <div onClick={() => {
                                                    if (video['video_id'] === 1) {
                                                        window.alert('첫번째 강의입니다')
                                                    } else {
                                                        var _video = list.find(x => x['video_id'] === parseInt(video['video_id']) - 1)
                                                        setVideo(_video)
                                                    }
                                                }}
                                                     style={{display: 'flex', alignItems: 'center', border: '1px solid gray', padding: '1px 1vw'}}>
                                                    <img alt={'prev'} src={require('../../../img/prev.png')} style={{width: '0.5vw'}} />
                                                    <span style={{fontFamily: 'Spoqa Han Sans Neo', fontSize: '0.9vw', fontWeight: 'bold', marginLeft: '0.5vw'}}>이전</span>
                                                </div>

                                                <div style={{display: 'flex', alignItems: 'center', margin: '0 0.8vw'}}>
                                                    <span style={{fontWeight: 'bold', color: MainColor, fontSize : '1.3vw'}}>{'0' + video['video_id']}</span>
                                                    <span style={{margin: '0 0.2vw'}}>/</span>
                                                    <span style={{fontSize: '1vw'}}>{'0' + list.length}</span>
                                                </div>

                                                <div onClick={() => {
                                                    if(video['video_id'] === 7){
                                                        window.alert('마지막 강의입니다')
                                                    } else{
                                                        var _video =list.find(x => x['video_id'] === parseInt(video['video_id']) + 1)
                                                        setVideo(_video)
                                                    }
                                                }}
                                                     style={{display: 'flex', alignItems: 'center', border: '1px solid gray', padding: '1px 1vw'}}>
                                                    <span style={{fontFamily: 'Spoqa Han Sans Neo', fontSize: '0.9vw', fontWeight: 'bold', marginRight: '0.5vw'}}>다음</span>
                                                    <img alt={'next'} src={require('../../../img/next.png')} style={{width: '0.5vw'}} />
                                                </div>
                                            </div>

                                        </div>

                                        <Player style={{width: '100%'}} video={video}
                                                insertProgress={insertProgress}/>
                                    </div>
                                }

                                <div style={{
                                    width: '23%',  alignItems: 'center',
                                    display: 'flex', flexDirection: 'column',
                                }}>

                                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: '1.85vh'}}>
                                        <span style={{fontFamily: 'Spoqa Han Sans Neo', fontSize: '1vw', fontWeight: 'bold'}}>목차 가이드</span>
                                    </div>

                                    {
                                        list.map((video, vIndex) => {
                                            return (
                                                <div key={vIndex}
                                                     onClick={() => {setVideo(video)}}
                                                     style={{
                                                         width: '100%', height: '10vh', cursor: 'pointer',
                                                         display: 'flex', flexDirection: 'column',
                                                         alignItems: 'center', justifyContent: 'center',
                                                         marginTop: vIndex && '7px', backgroundColor: 'white'}}>

                                                    <div style={{
                                                        width: '90%',
                                                        display: 'flex', flexDirection: 'column'}}>

                                                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: '0.5vw'}}>
                                                            <span style={{fontFamily: 'Spoqa Han Sans Neo', fontSize: '1vw', fontWeight: 'bold'}}>{video['title']}</span>
                                                        </div>

                                                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                                                            <div style={{
                                                                display: 'flex', alignItems: 'center',
                                                                fontFamily: 'Spoqa Han Sans Neo', fontSize: '0.8vw',
                                                                color: MainColor, fontWeight: 'bold'
                                                            }}>
                                                                <span>{video['recent_time'] ? getMin(video['recent_time']) : '00:00'}</span>
                                                                <span style={{margin: '0 0.5vw'}}>/</span>
                                                                <span>{getMin(video['running_time'])}</span>
                                                            </div>

                                                            {
                                                                video['play_time'] &&
                                                                (video['play_time'] / video['running_time']) * 100 > 90 ?
                                                                    <div style={{
                                                                        width: '5vw', height: '1.8vw',
                                                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                        borderRadius: '1vw', backgroundColor: SubColor03,
                                                                    }}>
                                                                        <span style={{fontFamily: 'Spoqa Han Sans Neo', color: MainColor, fontSize: '1vw', fontWeight: 'bold'}}>완료</span>
                                                                    </div>
                                                                    :
                                                                    <span />
                                                            }

                                                        </div>

                                                    </div>

                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                    }
                </div>
            </PcContentWrapper>
        </PcContainer>
    )
}

export default Pc_video
