import React, {useState, useEffect} from "react";

const Mobile_reference = () => {

    return (
        <div>

        </div>
    )
}

export default Mobile_reference
