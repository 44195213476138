import React, {useState, useEffect} from "react";


const Mobile_video = () => {


    return (
        <div>

        </div>
    )
}

export default Mobile_video
