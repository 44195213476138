
export default function User_reducers(state ={id: '', password: '', company_name: '', auth_code : ''}, action){
    switch (action.type){
        case 'LOG_IN':
            return {
                ...state,
                id: action['user']['user_id'],
                password : action['user']['password'],
                company_name: action['user']['company_name'],
                auth_code : action['user']['auth_code'],
            }
        case 'LOG_OUT':
            return {id: '', password: '', company_name: '', auth_code: ''}
        default:
            return state
    }
}
