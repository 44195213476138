import React, {useState, useEffect, useRef} from 'react'
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";

import { Line } from 'rc-progress';

const Player = (props) => {

    var videoRef = useRef(null)
    const {User_reducers} = useSelector(state => state)
    const isMobile = useMediaQuery({maxWidth: 767});


    const [hover, setHover] = useState(false)
    const [play, setPlay] = useState(false)
    const [load, setLoad] = useState(false)

    const [runningTime, setRunningTime] = useState(0)
    const [playTime, setPlayTime] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [beforeVideo, setBeforeVideo] = useState(null)
    const [poster, setPoster] = useState(null);


    useEffect(() => {

        const interval = setInterval(() => {
            if (videoRef.current && !videoRef.current.paused) {
                setCurrentTime(videoRef.current.currentTime)
                setPlayTime(videoRef.current.currentTime)
            }
        }, 1000);
        return () => clearInterval(interval)
    }, [])


    useEffect(() => {
        if(videoRef.current && !videoRef.current.paused){
            props['insertProgress']({
                id: props.video.id,
                user_id: User_reducers['id'],
                video_id: props.video['video_id'],
                play_time: Math.round(playTime),
                recent_time: currentTime
            })
        }
    }, [isMobile])

    // useEffect(() => {
    //     document.addEventListener('keyup', e => {
    //         try {
    //             if (e.code === 'Space') {
    //                 clickPlay()
    //             }
    //         } catch (err) {
    //             console.log(err)
    //         }
    //     })
    // },[])


    useEffect(() => {

        const setControlValue = async () => {
            var {video} = props
            setLoad(false)
            setPlay(false)

            if (videoRef.current) {
                const _currentTime = video['running_time'] <= video['recent_time'] ? 0 : video['recent_time']

                await setRunningTime(video['running_time'])
                await setPlayTime(video['play_time'] || 0)
                await setCurrentTime(_currentTime)
                videoRef.current.currentTime = video['recent_time'] || 0

                setTimeout(() => {
                    setLoad(true)
                },300)
            }
        }

        setControlValue()
        return () => {
            setBeforeVideo(props.video)
        }
    },[props.video])



    useEffect(() => {
        var _poster = props.video['video_id'] + '_thumbnail.png'
        setPoster(_poster)
    },[props.video])

    useEffect(() => {

        return () => {
            if(!load && beforeVideo){
                // console.log('BEFORE_VIDEO', beforeVideo)
                // console.log('CURRENT_VIDEO', props.video)
                //
                // console.log('BEFORE_PLAY_TIME', playTime)
                // console.log('BEFORE_CURRENT_TIME', currentTime)

                props.insertProgress({
                    id: beforeVideo.id,
                    user_id: User_reducers['id'],
                    video_id: beforeVideo['video_id'],
                    play_time: Math.round(playTime),
                    recent_time: currentTime
                })
            }
        }
    },[load])

    const clickPlay = async () => {
        if (!play) {
            if(currentTime === 0){
                props.insertProgress({
                    id: props.video.id,
                    user_id: User_reducers['id'],
                    video_id: props.video['video_id'],
                    play_time: 0,
                    recent_time: 0
                })
            }
            videoRef.current.play()
        } else {
            props.insertProgress({
                id: props.video.id,
                user_id: User_reducers['id'],
                video_id: props.video['video_id'],
                play_time: Math.round(playTime),
                recent_time: currentTime
            })
            videoRef.current.pause()
        }

        setPlay((pre) => !pre)
    }


    const getMin = (sec) => {

        const mm = parseInt((sec%3600)/60) < 10 ? '0' + parseInt((sec%3600)/60) : parseInt((sec%3600)/60)
        const ss = Math.round(sec%60) < 10 ? '0' + Math.round(sec%60) : Math.round(sec%60)

        return (`${String(mm)}:${String(ss)}`)
    }


    const controlBar = (props) => {

        if(hover || videoRef.current.paused) {
            return (
                <div style={{
                    width: '100%', position: 'absolute', bottom: 0,
                    display: 'flex', alignItems: 'center',
                    padding: '5px 0', backgroundColor: '#00000020',
                }}>

                    <div onClick={() => clickPlay()}
                         style={{width: '8%', display: 'flex', justifyContent: 'center'}}>
                        {
                            videoRef.current && videoRef.current.paused ?
                                <img alt={'play'} style={{width: '0.8vw'}}
                                     src={require('../img/play.png')} />
                                :
                                <img alt={'pause'} style={{width: '1vw'}}
                                     src={require('../img/pause.png')} />
                        }
                    </div>
                    <div style={{width: '62%', display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '95%', display: 'flex', alignItems: 'center'}}>
                            <Line percent={videoRef.current && (currentTime / videoRef.current.duration) * 100}
                                  strokeWidth={1} strokeColor={'#4078e0'}
                                  trailWidth={1} trailColor={'#ffffff'} />
                        </div>
                    </div>

                    <div style={{width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                        <div style={{
                            width: '40%', display: 'flex',
                            alignItems: 'center',justifyContent: 'center',
                            fontFamily: 'Spoqa Han Sans Neo', fontSize: '0.9vw',
                        }}>
                            <span style={{}}>{getMin(currentTime)}</span>
                            <span style={{margin: '0 0.1vw'}}>/</span>
                            <span style={{}}>{getMin(runningTime)}</span>
                        </div>

                        <div style={{
                            width: '35%', display: 'flex',
                            alignItems: 'center', justifyContent: 'flex-end',
                            fontFamily: 'Spoqa Han Sans Neo', fontSize: '1vw',
                        }}>
                            <span style={{
                                fontWeight: 'bold', color: 'white', cursor: 'pointer'}}
                                  onClick={() => {
                                      if (videoRef.current) {
                                          videoRef.current.playbackRate = 1
                                      }}}>x1</span>
                            <span style={{marginLeft: '0.8vw', fontWeight: 'bold', color: 'white', cursor: 'pointer'}}
                                  onClick={() => {
                                      if(videoRef.current){
                                          videoRef.current.playbackRate = 1.5
                                      }}}>x1.5</span>
                        </div>

                        <div onClick={() => videoRef.current.requestFullscreen()}
                             style={{width: '25%', display: 'flex', justifyContent: 'center'}}>
                            <img alt={'full-screen'} src={require('../img/full_screen.png')}
                                 style={{width: '1.4vw'}}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (

        <div onMouseEnter={() => setHover(true)}
             onMouseLeave={() => setHover(false)}
             style={{
                 ...props.style, position: 'relative',
                 display: 'flex', justifyContent: 'center'
             }}>

            <video ref={videoRef}
                   key={props.video.video_id}
                   controls={false}
                   poster={poster && require('../img/' + poster)}
                   style={{width: '100%'}}
                   onEnded={() => {
                       // console.log('END', props.video.video_id, currentTime)
                       props.insertProgress({
                           id: props.video.id,
                           user_id: User_reducers['id'],
                           video_id: props.video['video_id'],
                           play_time: Math.round(playTime),
                           recent_time: currentTime
                       })
                   }}>

                <source src={require(`../videos/${props.video['file_name']}`)} type={'video/mp4'}></source>
            </video>



            {videoRef.current && load && controlBar()}

        </div>

    )
}

export default Player
