import React, {useState, useEffect} from "react";
import {CheckSquareFill, Square, Download, Search} from "react-bootstrap-icons";

import {API_SERVER} from "../../../config";
import {PcContainer, PcContentWrapper, MainColor, SubColor02, SubColor03} from "../../../module/styled";
import MenuBar from "../../../module/common/menuBar";

const _division = [{key :'e', show_key: '환경 (E)',}, {key:'s', show_key: '사회 (S)'}, {key: 'g', show_key: '지배구조 (G)',}]


const Pc_reference = ({reference}) => {


    const [searchInput, setSearchInput] = useState('');
    const [condition, setCondition] = useState([]);

    const [list, setList] = useState({
        thead: [
            {key: 'show_index', show_key: 'No.', width: '5%',},
            {key: 'division', show_key: '구분', width: '15%'},
            {key: 'survey_code', show_key: '해당지표', width: '15%'},
            {key: 'file_name', show_key: '파일명', width: '55%',},
            {key: 'download', show_key: '다운로드', width: '10%'},
        ],
        tbody: null
    })

    useEffect(() => {
        console.log("REF", reference)
        setList(prevState => ({
            ...prevState,
            tbody: reference
        }))
    },[reference])


    useEffect(() => {
        var _list = JSON.parse(JSON.stringify(reference));
        if(condition.length > 0){
            _list = _list.filter(x => condition.includes(x['division']));
        }

        setList(prevState => ({
            ...prevState,
            tbody: _list
        }))
    }, [condition])


    useEffect(() => {
        if(searchInput.length === 0){
            setList(prevState => ({
                ...prevState,
                tbody: reference
            }))
        }
    },[searchInput])


    const dataSearch = async (value) => {
        if (condition.length > 0) {
            await setCondition([]);
        }

        var _value = value.replaceAll(' ','');
        var _list = [...reference];

        _list = _list.filter(x => x['file_name'].replaceAll(' ','').includes(_value));
        setList(prevState =>  ({
            ...prevState,
            tbody: _list
        }))
    }

    return (
        <PcContainer>
            <MenuBar device={'pc'}/>
            <PcContentWrapper>
                {
                    !Object.keys(list).find(key => !list[key]) &&
                    <div style={{width: '90%', marginBottom: '5vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {
                                    _division.map((item, idx) => {
                                        return (
                                            <div key={idx}
                                                 style={{
                                                     display: 'flex', alignItems: 'center',
                                                     marginLeft: idx && '1vw', cursor: 'pointer',
                                                 }}
                                                 onClick={() => {
                                                     var _condition = [...condition];
                                                     var _fIdx = _condition.findIndex(x => x === item.key);

                                                     if(_fIdx >= 0){
                                                         _condition.splice(_fIdx, 1)
                                                     } else {
                                                         _condition.push(item.key)
                                                     }

                                                     setCondition(_condition)
                                                 }}>
                                                {
                                                    condition.includes(item.key) ?
                                                        <CheckSquareFill color={MainColor} size={'1vw'}/> :
                                                        <Square color={'#aaaaaa'} size={'1vw'}/>
                                                }
                                                <span style={{marginLeft: 8, fontSize: '0.9vw', fontWeight: 'bold'}}>{item.show_key}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <input value={searchInput || ''}
                                       style={{width: '10vw', height: '3vh', border: '1px solid lightgray', fontSize: '0.9vw'}}
                                       onKeyPress={async (e) => {
                                           if(e.key === 'Enter'){
                                               await dataSearch(searchInput)
                                           }
                                       }}
                                       onChange={(e) => {
                                           setSearchInput(e.target.value)
                                       }}/>

                                <div style={{
                                    width: '2vw', height: '3vh', display: 'flex',
                                    alignItems: 'center', justifyContent: 'center',
                                    backgroundColor: 'white', border: '1px solid lightgray',
                                    marginLeft: '0.5vw', cursor: 'pointer',
                                }}
                                     onClick={async () => {
                                         await dataSearch(searchInput)
                                     }}>
                                    <Search size={'1vw'} color={MainColor}/>
                                </div>
                            </div>
                        </div>


                        <div style={{width: '100%', marginTop: '2vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                            <div style={{
                                position: 'sticky', top: 0,
                                width: '100%', height: '4vh', display: 'flex',
                                alignItems: 'center', backgroundColor: SubColor03,
                            }}>
                                {
                                    list['thead'].map((head, hIdx) => {
                                        return (
                                            <div key={hIdx}
                                                 style={{
                                                     width: head.width, height: '100%', display: 'flex',
                                                     alignItems: 'center', justifyContent: 'center',
                                                 }}>
                                                {
                                                    head.key === 'file_name' ?
                                                        <div style={{width: '95%', display: 'flex', justifyContent: 'flex-start'}}>
                                                            <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{head.show_key}</span>
                                                        </div>
                                                        :
                                                        <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{head.show_key}</span>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                {
                                    list['tbody'].sort((a, b) => {return a['show_index'] - b['show_index']}).map((item, idx) => {
                                        return (
                                            <div key={idx}
                                                 style={{
                                                     width: '100%', height: '5vh', display: 'flex',
                                                     alignItems: 'center', backgroundColor: idx % 2 ? '#eeeeee' : '#f8f8f8',
                                                     borderTop: '1px solid #eeeeee'
                                                 }}>
                                                {
                                                    list['thead'].map((head, hIdx) => {
                                                        return (
                                                            <div key={hIdx}
                                                                 style={{
                                                                     width: head.width, height: '100%', display: 'flex',
                                                                     alignItems: 'center', justifyContent: 'center'
                                                                 }}>
                                                                {
                                                                    (() => {
                                                                        switch (head.key){
                                                                            case 'show_index':
                                                                                return (
                                                                                    <span style={{fontSize: '0.8vw', fontWeight: 500, color: 'gray'}}>{item[head.key]}</span>
                                                                                )
                                                                            case 'division':
                                                                                return (
                                                                                    <span style={{fontSize: '0.8vw', fontWeight: 500}}>{
                                                                                       _division.find(x => x['key'] === item[head.key])['show_key']
                                                                                    }</span>
                                                                                )
                                                                            case 'survey_code':
                                                                                return (
                                                                                    <span style={{fontSize: '0.8vw', fontWeight: 500}}>{item[head.key]}</span>
                                                                                )
                                                                            case 'download':
                                                                                return (
                                                                                    <Download size={'1.1vw'} color={'gray'} style={{cursor: 'pointer'}}
                                                                                              onClick={() => window.open(API_SERVER + '/fileList/file/' + item['file_path'])}/>
                                                                                )
                                                                            default:
                                                                                return (
                                                                                    <div style={{width: '95%', display: 'flex', justifyContent: 'flex-start'}}>
                                                                                        <span style={{fontSize: '0.8vw', fontWeight: 500}}>{item[head.key]}</span>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    })()
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                    </div>
                }
            </PcContentWrapper>
        </PcContainer>
    )
}

export default Pc_reference
