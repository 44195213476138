import React, {useState, useEffect} from "react";


const Mobile_survey_detail = () => {

    return (
        <div>

        </div>
    )
}

export default Mobile_survey_detail
