import React, {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {PersonCircle, LockFill} from "react-bootstrap-icons";

import {API_SERVER} from "../../../config";
import {MainColor, SubColor02} from "../../../module/styled";

const Pc_auth = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    var infoRef = useRef([]);
    const [userInfo, setUserInfo] = useState({user_id: null, password: null});


    const signIn = async (info) => {
        await fetch(API_SERVER + '/user/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                id: info['user_id'],
                password: info['password']
            })
        })
            .then(res => res.json())
            .then(json => {
                if (json['suc']) {
                    dispatch({type: 'LOG_IN', user: json.user})
                    navigate('/', {replace: true})
                } else {
                    alert('아이디와 비밀번호를 확인해 주세요.')
                }
            })
    }


    return (
        <div style={{width: '100vw', height: '100vh', display: 'flex',}}>

            <div style={{
                width: '35vw', height: '100%',
                display: 'flex', flexDirection: 'column',
                alignItems : 'center',
            }}>
                <div style={{width: '65%', marginTop: '17.34vh'}}>
                    <span style={{fontFamily: 'Roboto', fontSize: '2vw',fontWeight: 'bold'}}>Sign In</span>


                    <div style={{width: '100%', marginTop: '16vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        {
                            [
                                {
                                    key: 'user_id', show_key: 'ID',
                                    icon: <PersonCircle size={'1.5vw'} color={MainColor}/>
                                },
                                {
                                    key: 'password', show_key: 'PASSWORD',
                                    icon: <LockFill size={'1.5vw'} color={MainColor}/>
                                },
                            ].map((item, idx) => {
                                return (
                                    <div key={idx}
                                         style={{
                                             width: '100%', display: 'flex', flexDirection: 'column',
                                             alignItems: 'flex-start', marginTop: idx && '6.3vh'
                                         }}>
                                        <span style={{fontFamily: 'Roboto', fontSize: '1.2vw',fontWeight: 'bolder'}}>{item.show_key}</span>

                                        <div style={{
                                            width: '100%', height: '6.63vh', display: 'flex',
                                            alignItems: 'center', justifyContent: 'space-between',
                                            marginTop: '1vh', border: '1px solid #c0c0c0', borderRadius: 5
                                        }}>
                                            <div style={{
                                                width: '15%', height: '100%', display: 'flex',
                                                alignItems: 'center', justifyContent: 'center'
                                            }}>
                                                {item.icon}
                                            </div>

                                            <input ref={(el) => infoRef.current[item.key] = el}
                                                   value={userInfo[item.key] || ''} type={item.key}
                                                   style={{
                                                       width: '80%', height: '90%',
                                                       border: 'none', outline: 'none',
                                                       fontSize: '1.1vw', background: 'none',
                                                   }}
                                                   onKeyPress={async (e) => {
                                                       if(e.key === 'Enter'){
                                                           if(!Object.keys(userInfo).find(key => !userInfo[key])){
                                                               await signIn(userInfo);
                                                           } else {
                                                               if(item.key === 'user_id'){
                                                                   infoRef.current['password'].focus();
                                                               }
                                                           }
                                                       }
                                                   }}
                                                   onChange={(e) => {
                                                       setUserInfo(prevState => ({
                                                           ...prevState,
                                                           [item.key] : e.target.value
                                                       }))
                                                   }}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div  style={{
                        width: '100%', height: '6.63vh', display: 'flex',
                        alignItems: 'center', justifyContent: 'center',
                        marginTop: '14vh', borderRadius: '5px', cursor: 'pointer',
                        backgroundColor: SubColor02
                    }}
                          onClick={async () => {
                              await signIn(userInfo)
                          }}>
                        <span style={{fontFamily: 'Spoqa Han Sans Neo', color: 'white', fontWeight: 'bold', fontSize: '1.2vw'}}>로그인</span>
                    </div>

                </div>
            </div>

            <div style={{
                width: '65vw', height: '100%', position: 'relative',
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
            }}>

                <img style={{position: 'absolute', width: '65vw', height: '100vh', zIndex: -1}} src={require('../../../img/auth_background.png')}/>

                <div style={{
                    width: '33vw',
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center', marginTop: '16.22vh',
                }}>
                    <span style={{fontFamily: 'Spoqa Han Sans Neo', color: 'white', fontSize: '2vw'}}>중흥토건 x 협력사 <span style={{fontWeight: 'bold'}}>ESG 교육</span></span>

                    <div style={{
                        width: '100%',lineHeight: 1.8,
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'center', marginTop: '6.73vh'
                    }}>
                        <span style={{fontFamily: 'Spoqa Han Sans Neo', fontSize: '1.1vw', color: 'white'}}>중흥토건은 사회의 성실한 일원으로서</span>
                        <span style={{fontFamily: 'Spoqa Han Sans Neo', fontSize: '1.1vw', color: 'white'}}>우리 모두의 밝은 미래를 만들고자 오늘도 최선을 다하고 있습니다.</span>
                    </div>
                </div>

                <div style={{
                    width: '46vw',
                    position: 'absolute', top: '47vh',
                    display: 'flex', justifyContent: 'center'
                }}>
                    <img alt={'arch'}
                         src={require('../../../img/auth_arch.png')}
                         style={{width: '80%'}}/>

                    <img alt={'back_logo'}
                         src={require('../../../img/company_logo.png')}
                         style={{width: '15vw', position: 'absolute', top: '25vh'}}/>
                </div>
            </div>
        </div>
    )
}

export default Pc_auth
