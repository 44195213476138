import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {Stack, Calendar, CardChecklist} from "react-bootstrap-icons";

import ProgressBar from 'react-bootstrap/ProgressBar';
import moment from "moment";

import {PcContainer, PcContentWrapper, MainColor, SubColor01, SubColor02, SubColor03} from "../../../module/styled";
import MenuBar from "../../../module/common/menuBar";


const Pc_home = ({report, survey, surveyList}) => {

    const user = useSelector(store => store['User_reducers']);

    const adminTableHead = [
        {key: 'no', show_key: '연번', width: '5%'},
        {key: 'course', show_key: '과정명', width: '26%'},
        {key: 'company_name', show_key: '기업명', width: '20%'},
        {key: 'created_at', show_key: '시작일', width: '12%'},
        {key: 'updated_at', show_key: '종료일', width: '12%'},
        {key: 'progress', show_key: '수강현황', width: '15%'},
        {key: 'note', show_key: '비고', width: '10%'},
    ]

    const [table, setTable] = useState({
        thead : [
            {key: 'category', show_key: '진도율'},
            {key: 1, show_key: '1강'},
            {key: 2, show_key: '2강'},
            {key: 3, show_key: '3강'},
            {key: 4, show_key: '4강'},
            {key: 5, show_key: '5강'},
            {key: 6, show_key: '6강'},
            {key: 7, show_key: '7강'},
            {key: 'check', show_key: '수료구분'},
        ],
        tbody: null,
    })


    useEffect(() => {
        if(user['auth_code'] === 'user'){
            var _tbody = [];
            var _obj = {category : null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null, check: null,}

            for(let i =0; i < 3; i++){
                switch (i){
                    case 0:
                        _tbody.push({
                            ..._obj,
                            category: '수료기준',
                            4: Math.round(report.filter(x => x['running_time'] * 0.9 <= x['play_time']).length/report.length * 100) + '%'
                        })
                        break;
                    case 1:
                        var _body = report.reduce((pre, cur) => {
                            var {video_id, running_time, play_time} = cur
                            pre[video_id] = Math.round((play_time / running_time) * 100) + '%'
                            return pre
                        }, {})

                        _tbody.push({
                            ..._body,
                            category: '나의 현황',
                            check: Math.round(report.filter(x => x['running_time'] * 0.9 <= x['play_time']).length/report.length * 100) >= 90 ? '수강완료' : '미수강',
                        })
                        break;
                    case 2:
                        var _body = report.reduce((pre, cur) => {
                            var {video_id, running_time, play_time} = cur
                            pre[video_id] = running_time * 0.9 <= play_time ? '수강완료' : '미수강'
                            return pre
                        }, {})

                        _tbody.push({
                            ..._body,
                            category: '완료 여부',
                            check: null,
                        })
                        break;
                    default:
                        break;
                }
            }

            setTable(prevState => ({
                ...prevState,
                tbody: _tbody
            }))
        } else {

        }

    },[report])

    const getDate = (type, report) => {
        switch (type){
            case 'min':
                var minDate = report.reduce((a,b) => {
                    if(!a['created_at']){
                        return b
                    } else if(!b['created_at']){
                        return a
                    } else{
                        return a['created_at'] < b['created_at'] ? a : b
                    }
                })

                return minDate['created_at'] ? moment(minDate['created_at']).format('YYYY-MM-DD') : '미진행'
            case 'max' :
                var maxCheck = report.filter(x => Math.floor(x['play_time']/x['running_time'] * 100) >= 90).length >= 7 ? true : false
                var maxDate = report.reduce((a, b) => {
                    if (!a['updated_at']) {
                        return b
                    } else if (!b['updated_at']) {
                        return a
                    } else {
                        return a['updated_at'] > b['updated_at'] ? a : b
                    }
                })

                return maxCheck && maxDate['updated_at'] ? moment(maxDate['updated_at']).format('YYYY-MM-DD') : ''
            default:
                break;
        }
    }

    return (
        <PcContainer>
            <MenuBar device={'pc'}/>
            <PcContentWrapper>
                {
                    (() => {
                        switch (user['auth_code']){
                            case 'admin':
                                return (
                                    <div style={{width: '90%', marginBottom: '5vh', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <span style={{fontSize: '0.9vw', fontWeight: 500, color: 'gray'}}>수강 현황</span>
                                        </div>

                                        <div style={{
                                            position: 'sticky', top: 0, marginTop: '1vh',
                                            width: '100%', height: '3.5vh', display: 'flex',
                                            alignItems: 'center', justifyContent: 'flex-start',
                                            backgroundColor: SubColor03,
                                        }}>
                                            {
                                                adminTableHead.map((head, hIdx) => {
                                                    return (
                                                        <div key={hIdx}
                                                             style={{
                                                                 width: head.width, height: '100%', display: 'flex',
                                                                 alignItems: 'center', justifyContent: 'center'
                                                             }}>
                                                            <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{head.show_key}</span>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>

                                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                            {
                                                report.map((item, idx) => {
                                                    return (
                                                        <div key={idx}
                                                             style={{
                                                                 width: '100%', height: '5vh', display: 'flex',
                                                                 alignItems: 'center', justifyContent: 'flex-start',
                                                                 backgroundColor: idx % 2 ? '#f8f8f8' : 'white',
                                                                 borderTop: '1px solid #eeeeee'
                                                             }}>
                                                            {
                                                                adminTableHead.map((head, hIdx) => {
                                                                    return (
                                                                        <div key={hIdx}
                                                                             style={{
                                                                                 width: head.width, height: '100%', display: 'flex',
                                                                                 alignItems: 'center', justifyContent: 'center',
                                                                             }}>
                                                                            {
                                                                                (() => {
                                                                                    switch (head.key){
                                                                                        case 'no':
                                                                                            return (
                                                                                                <span style={{fontSize: '0.8vw', fontWeight: 'bold', color: 'gray'}}>{idx + 1}</span>
                                                                                            )
                                                                                        case 'course':
                                                                                            return (
                                                                                                <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>㈜ 중흥토건 협력사 ESG 교육</span>
                                                                                            )
                                                                                        case 'created_at':
                                                                                            return (
                                                                                                <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{item[head.key] && moment(item[head.key]).format('YYYY-MM-DD')}</span>
                                                                                            )
                                                                                        case 'updated_at':
                                                                                            return (
                                                                                                <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{item['progress'] === 7 && item[head.key] && moment(item[head.key]).format('YYYY-MM-DD')}</span>
                                                                                            )
                                                                                        case 'progress':
                                                                                            return (
                                                                                                <ProgressBar now={Math.round(item[head.key]/7 * 100)}
                                                                                                             label={`${item[head.key]}/7`}
                                                                                                             style={{width: '80%',  fontSize: '0.7vw', fontWeight: 'bold'}}/>
                                                                                            )
                                                                                        case 'note':
                                                                                            return (
                                                                                                <div style={{width: '95%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', lineHeight: 1.1}}>
                                                                                                    <span style={{fontSize: '0.7vw', letterSpacing: 0}}>{`ID : ${item['user_id']}`}</span>
                                                                                                    <span style={{fontSize: '0.7vw', letterSpacing: 0}}>{`PW : ${item['password']}`}</span>
                                                                                                </div>
                                                                                            )
                                                                                        default:
                                                                                            return (
                                                                                                <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{item[head.key]}</span>
                                                                                            )
                                                                                    }
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            case  'user':
                                return (
                                    <div style={{width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                                        <span style={{fontSize: '0.9vw', fontWeight: 500, color: 'gray'}}>수강현황</span>
                                        <span style={{marginTop: '2vh', fontSize: '1.3vw', fontWeight: 'bold'}}>중흥토건 협력사 ESG 지원 교육</span>

                                        <div style={{
                                            width: '100%', padding: '4vh 0', display: 'flex',
                                            alignItems: 'center', justifyContent: 'center',
                                            marginTop: '3vh', backgroundColor: 'white',
                                        }}>
                                            <div style={{width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                                                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    {
                                                        [
                                                            {
                                                                key: 'period', show_key: '학습 기간',
                                                                width: '36%', icon: <Calendar width={'1.1vw'} color={SubColor02}/>,},
                                                            {
                                                                key: 'percent', show_key: '학습 진도율',
                                                                width: '61%', icon: <Stack width={'1.1vw'} color={SubColor02}/>,
                                                            },
                                                        ].map((item, idx) => {
                                                            return (
                                                                <div key={idx}
                                                                     style={{
                                                                         width : item.width, display: 'flex', flexDirection: 'column',
                                                                         alignItems: 'flex-start', border: `1px solid ${SubColor03}`
                                                                     }}>
                                                                    <div style={{
                                                                        width: '100%', height: '4vh', display: 'flex',
                                                                        alignItems: 'center', justifyContent: 'flex-start',
                                                                        backgroundColor: SubColor03
                                                                    }}>
                                                                        <div style={{marginLeft: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                                            {item.icon}
                                                                            <span style={{marginLeft: 8, color: SubColor02, fontSize: '0.9vw', fontWeight: 'bold'}}>{item.show_key}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div style={{
                                                                        width: '100%', height: '7vh', display: 'flex',
                                                                        alignItems: 'center', justifyContent: 'center'
                                                                    }}>
                                                                        {
                                                                            (() => {
                                                                                switch (item.key) {
                                                                                    case 'period':
                                                                                        return (
                                                                                            <span style={{fontSize: '1vw', fontWeight: 'bold'}}>{getDate('min', report)} ~ {getDate('max', report)}</span>
                                                                                        )
                                                                                    case 'percent':
                                                                                        var _percent = Math.round(report.filter(x => x['running_time'] * 0.9 <= x['play_time']).length/report.length * 100)

                                                                                        return (
                                                                                            <div style={{
                                                                                                width: '90%', display: 'flex', alignItems: 'center',
                                                                                                justifyContent: 'space-between',
                                                                                            }}>
                                                                                                <ProgressBar now={_percent}
                                                                                                             label={`${report.filter(x => x['running_time'] * 0.9 <= x['play_time']).length}/${report.length}`}
                                                                                                             style={{
                                                                                                                 width: '85%', height: '3vh', fontWeight: 'bold',
                                                                                                                 border: `1px solid ${MainColor}80`, background: 'none'
                                                                                                             }}/>

                                                                                                <span style={{fontSize: '0.9vw', fontWeight: 'bold'}}>{_percent}%</span>
                                                                                            </div>
                                                                                        )
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                            })()
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                <div style={{
                                                    width: '100%', marginTop: '3vh', display: 'flex',
                                                    flexDirection: 'column', alignItems: 'flex-start',
                                                    border: `1px solid ${SubColor03}`
                                                }}>

                                                    <div style={{
                                                        width: '100%', height: '4vh', display: 'flex',
                                                        alignItems: 'center', justifyContent: 'flex-start',
                                                        backgroundColor: SubColor03
                                                    }}>
                                                        <div style={{marginLeft: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                                            <CardChecklist size={'1.1vw'} color={SubColor02}/>
                                                            <span style={{marginLeft: 8, color: SubColor02, fontSize: '0.9vw', fontWeight: 'bold'}}>자가진단 진행도</span>
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        width: '100%', height: '7vh', display: 'flex',
                                                        alignItems: 'center', justifyContent: 'center'
                                                    }}>
                                                        <div style={{width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                            <ProgressBar now={Math.round(survey.filter(x => x['answer_radio']).length/surveyList.length * 100)}
                                                                         label={`${survey.filter(x => x['answer_radio']).length}/${surveyList.length}`}
                                                                         style={{
                                                                             width: '90%', height: '3vh', fontWeight: 'bold',
                                                                             border: `1px solid ${MainColor}80`, background: 'none'
                                                                         }}/>

                                                            <span style={{fontSize: '0.9vw', fontWeight: 'bold'}}>{Math.round(survey.filter(x => x['answer_radio']).length/surveyList.length * 100) + '%'}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {
                                            !Object.keys(table).find(key => !table[key]) &&
                                            <div style={{
                                                width: '100%', padding: '4vh 0', display: 'flex',
                                                alignItems: 'center', justifyContent: 'center',
                                                marginTop: '2vh', backgroundColor: 'white',
                                            }}>
                                                <div style={{
                                                    width: '90%', display: 'flex', flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                }}>
                                                    <div style={{width: '100%', height: '4.5vh', display: 'flex', alignItems: 'center', backgroundColor: SubColor03}}>
                                                        {
                                                            table['thead'].map((head, hIdx) => {
                                                                return (
                                                                    <div key={hIdx}
                                                                         style={{
                                                                             width: 100/table['thead'].length + '%', height: '100%', display: 'flex',
                                                                             alignItems: 'center', justifyContent: 'center',
                                                                         }}>
                                                                        <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{head.show_key}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                        {
                                                            table['tbody'].map((item, idx) => {
                                                                return (
                                                                    <div key={idx}
                                                                         style={{
                                                                             width: '100%', height: '5vh', display: 'flex',
                                                                             alignItems: 'center', justifyContent: 'flex-start',
                                                                             backgroundColor: idx % 2 ? '#eeeeee' : '#f8f8f8',
                                                                             // borderTop: '1px solid #eeeeee'
                                                                         }}>
                                                                        {
                                                                            table['thead'].map((head, hIdx) => {
                                                                                return (
                                                                                    <div key={hIdx}
                                                                                         style={{
                                                                                             width: 100/table['thead'].length + '%', height: '100%',
                                                                                             display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                                             backgroundColor: ['category', 'check'].includes(head.key) && SubColor03
                                                                                         }}>
                                                                                        {
                                                                                            item[head.key] &&
                                                                                            <span style={{fontSize: '0.8vw', fontWeight: 'bold'}}>{item[head.key]}</span>

                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            default:
                                break
                        }
                    })()
                }
            </PcContentWrapper>
        </PcContainer>
    )
}

export default Pc_home
