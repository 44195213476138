import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {API_SERVER} from "../../config";

import Pc_survey from "./pc_survey";
import Mobile_survey from "./mobile_survey";

const Survey = ({device}) => {

    const user = useSelector(store => store['User_reducers']);
    const [surveyList, setSurveyList] = useState(null);


    useEffect(() => {
        if(user['auth_code'] === 'admin'){
            fetch(API_SERVER + '/data/totalAnswerList')
                .then(res => res.json())
                .then(json => {
                    if(json['suc']){
                        setSurveyList(json.result)
                    }
                })
        } else {
            fetch(API_SERVER + '/data/getSurveyList')
                .then(res => res.json())
                .then(json => {
                    setSurveyList(json.result);
                })
        }
    },[])


    if(surveyList)
        switch (device){
            case 'pc':
                return <Pc_survey surveyList={surveyList}/>
            case 'mobile':
                return <Mobile_survey />
            default:
                break;
        }
}

export default Survey
