import styled from "styled-components";

export const MainColor = '#4079e0';
export const SubColor01 = '#c4d5f7';
export const SubColor02 = '#0c4da1';
export const SubColor03 = '#dae3f1';

export const PcMenuWidth = '17vw';
export const PcWidth = '83vw';

export const PcContainer = styled.div`
width: 100vw;
display: flex;
justify-content: flex-end;
`

export const PcContentWrapper = styled.div`
width: 83vw;
padding-top: 7vh;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
`
