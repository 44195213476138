
import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage/session'

import User_reducers from "./user";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["User_reducers"]
}

const rootReducer = combineReducers({
    User_reducers
})

export default persistReducer(persistConfig, rootReducer)
