import React, {useState, useEffect} from "react";

import {API_SERVER} from "../../config";
import Pc_survey_detail from "./pc_survey_detail";
import Mobile_survey_detail from "./mobile_survey_detail";

const Survey_detail = ({device}) => {

    const [surveyList, setSurveyList] = useState(null);

    fetch(API_SERVER + '/data/getSurveyList')
        .then(res => res.json())
        .then(json => {
            setSurveyList(json.result);
        })

    if(surveyList)
        switch (device){
            case 'pc':
                return <Pc_survey_detail surveyList={surveyList}/>
            case 'mobile':
                return <Mobile_survey_detail/>
            default:
                break;
        }
}

export default Survey_detail
