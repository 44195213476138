import React, {useState, useEffect} from "react";
import {API_SERVER} from "../../config";

import Pc_reference from "./pc_reference";
import Mobile_reference from "./mobile_reference";

const Reference = ({device}) => {

    const [reference, setReference] = useState(null);

    useEffect(() => {
        fetch(API_SERVER + '/data/getDataList')
            .then(res => res.json())
            .then(json => {
                // console.log("=== JSON ===", json);
                if(json['suc']){
                    setReference(json['result'])
                } else {
                    alert('자료를 불러오는데 오류가 발생하였습니다, 잠시 후 다시 시도해 주세요')
                    console.log(json['error'])
                }
            })
    },[])

    if(reference)
        switch (device){
            case 'pc':
                return <Pc_reference reference={reference}/>
            case 'mobile':
                return <Mobile_reference />
            default:
                break;
        }
}

export default Reference
