import './index.css';
import React from 'react';

import App from './App';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";

import {createStore} from "redux";
import {Provider} from "react-redux";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import rootReducer from './reducers'

const store = createStore(rootReducer);
const persistor = persistStore(store);

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </PersistGate>
    </Provider>

);
